import Dh from '@/helpers/date.helper';

import Utils from '@/helpers/utils';
import States from '@/helpers/states';

import CrossSell from '@/services/cross.sell.service';

const service = new CrossSell();

export default {
    name: 'LienholderSummaries',

    components: {},

    data() {
        return {
            lienholders: [],
            perPage: 25,
            totalRows: 0,
            currentPage: 1,

            searchTerm: '',
            filterTimeout: null,

            dateRange: {from: '', to: ''},
            appliedDateRange: {from: '', to: ''},

            conditions: ['New', 'Used'],
            condition: '', // Car condition

            states: States,
            state: 'FL', // Seller state

            sort: {
                total: -1
            }
        }
    },

    computed: {
        dateRangeText() {
            if (!this.appliedDateRange.from && !this.appliedDateRange.to) {
                return this.$t('date')
            } else if (this.appliedDateRange.from && this.appliedDateRange.to) {
                return `${Dh.customFormat(this.appliedDateRange.from, 'MMM, YYYY')} ${this.$t('to')} ${Dh.customFormat(this.appliedDateRange.to, 'MMM, YYYY')}`
            } else if (!this.appliedDateRange.from) {
                return `${this.$t('To')} ${Dh.customFormat(this.appliedDateRange.to, 'MMM, YYYY')}`
            } else if (!this.appliedDateRange.to) {
                return `${this.$t('From')} ${Dh.customFormat(this.appliedDateRange.from, 'MMM, YYYY')}`
            }
        },

        conditionText() {
            if (this.condition) {
                return this.condition
            }
            return this.$t('condition')
        },

        stateText() {
            if (this.state) {
                const idx = this.states.findIndex(el => el.abbreviation === this.state)
                return this.states[idx].name
            }
            return this.$t('state')
        }
    },

    mounted() {
        this.list();
    },

    methods: {
        async list() {
            const skip = this.perPage * (this.currentPage - 1);
            const res = await service.summaries('lienholder', this.searchTerm, this.appliedDateRange, this.condition, this.state, this.perPage, skip, this.sort);
            if (res && !res.error) {
                this.lienholders = res.data.lienholders;
                this.totalRows = res.data.count;
            }
        },

        clearCondition() {
            this.condition = '';
            this.list();
        },

        clearDates() {
            this.dateRange = {from: '', to: ''}
        },

        applyDates() {
            this.$hideModals();
            this.appliedDateRange = {...this.dateRange};
            this.list();
        },

        highlight(text) {
            return text ? Utils.HighlightText(text, this.searchTerm) : "N/A";
        },

        setCondition(val) {
            this.condition = val;
            this.$hideModals();
            this.list();
        },

        setState(val) {
            this.state = val.abbreviation;
            this.$hideModals();
            this.list();
        },

        toggleSort(type) {
            this.sort[type] = this.sort[type] === 1 ? -1 : 1
            this.list();
        },

        setPerPage(num) {
            this.perPage = +(num);
            this.$hideModals();
            this.list();
        },

        next() {
            this.currentPage += 1;
            this.list();
        },

        prev() {
            this.currentPage -= 1;
            this.list();
        },
    },

    watch: {
        searchTerm() {
            clearTimeout(this.filterTimeout);
            this.filterTimeout = setTimeout(() => {
                this.list();
            }, 500);
        },
    },

    filters: {
        formatNumber(val) {
            return val || '-'
            //return val.toLocaleString()
        },
    },
}